import React from 'react'

export default function Hero({ onOpenForm }) {
  return (
    <section className="relative h-[500px] text-white text-center flex flex-col items-center justify-center space-y-6 bg-cover bg-center" style={{backgroundImage: "url('/orl_pics-transformed.png')"}}>
      <div className="absolute inset-0 bg-black opacity-70"></div>
      <h1 className="text-5xl font-bold z-10 leading-tight">PROFESIONÁLNE ČISTENIE <br /> ODLUČOVAČOV ROPNÝCH LÁTOK</h1>
      <p className="max-w-2xl z-10 text-lg">
        Zbavíme Vás nečistôt a zároveň Vás naučíme, ako im zabrániť. Postaráme sa o čistotu Vašich odlučovačov ropných látok. Naša prevencia ušetrí čas aj peniaze.
      </p>
      <button 
        onClick={onOpenForm} 
        className="px-8 py-3 bg-orange-500 text-white rounded-full z-10 text-lg font-semibold hover:bg-orange-600 transition duration-300 ease-in-out transform hover:scale-105"
      >
        REZERVUJ TERMÍN
      </button>
    </section>
  )
}
