import React from 'react';

const ConfirmationModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md p-6">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Ďakujeme!</h2>
        <p className="text-gray-600 mb-6">Ďakujeme Vám za vyplnenie formuláru. Predpokladaná suma za čistenie ORL bola zaslaná na Váš mail.</p>
        <button 
          onClick={onClose}
          className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
        >
          Zavrieť
        </button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
