import React from 'react'
import { Layers, Truck, Fuel, Search } from 'lucide-react'

const services = [
  { 
    icon: Layers, 
    title: 'Vyplňte formulár',
    description: 'Jednoducho vyplňte náš online formulár s detailmi o vašom zariadení a požadovaných službách.'
  },
  { 
    icon: Search, 
    title: 'Cena',
    description: 'Na základe poskytnutých informácií vám pripravíme predpokladanú cenovú ponuku našich služieb.'
  },
  { 
    icon: Truck, 
    title: 'Vyčistenie',
    description: 'Náš tím odborníkov vykoná profesionálne čistenie vášho zariadenia s použitím najmodernejších technológií.'
  },
  { 
    icon: Fuel, 
    title: 'Spokojný zákazník',
    description: 'Vaše zariadenie bude fungovať efektívnejšie, čo vedie k úspore nákladov a spokojnosti zákazníkov.'
  },
]

export default function Services() {
  return (
    <section className="py-16 px-4 bg-white">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-12">
          <span className="px-6 py-3 bg-blue-600 text-white inline-block">Jednoducho a efektívne</span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {services.map((service, index) => (
            <div key={index} className="flex flex-col items-center text-center group">
              <div className="w-24 h-24 bg-blue-600 rounded-full flex items-center justify-center mb-6 group-hover:bg-orange-500 transition duration-300">
                <service.icon className="w-12 h-12 text-white" />
              </div>
              <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
              <p className="text-gray-600">
                {service.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
