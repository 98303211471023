import React, { useState, useRef, useEffect } from 'react'
import { MessageSquare, X } from 'lucide-react'
import axios from 'axios'  // Import axios

export default function ChatWidget() {
	const [isChatOpen, setIsChatOpen] = useState(false)
	const [showBubble, setShowBubble] = useState(true)
	const [messages, setMessages] = useState([
		{ content: "👋 Vitajte! Som tu, aby som odpovedal na vaše otázky ohľadom čistenia odlučovačov ropných látok (ORL). Ako vám môžem pomôcť?", sender: 'bot' },
	])
	const [input, setInput] = useState('')
	const scrollAreaRef = useRef(null)

	useEffect(() => {
		if (scrollAreaRef.current) {
			scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight
		}
	}, [messages])

	const handleSendMessage = async (e) => {
		e.preventDefault()
		if (input.trim() === '') return

		const userMessage = { content: input, sender: 'user' }
		setMessages(prevMessages => [...prevMessages, userMessage])
		setInput('')

		try {
			const response = await axios.post('http://localhost:5000/api/chat', { message: input })
			const botMessage = { content: response.data.reply, sender: 'bot' }
			setMessages(prevMessages => [...prevMessages, botMessage])
		} catch (error) {
			console.error('Error sending message:', error)
			setMessages(prevMessages => [...prevMessages, { content: "Sorry, I'm having trouble connecting. Please try again later.", sender: 'bot' }])
		}
	}

	return (
		<div className="fixed bottom-6 right-6 z-50 flex items-end">
			{showBubble && !isChatOpen && (
				<div className="mb-12 mr-2 bg-white p-2 rounded-lg shadow-lg text-s flex items-center max-w-xs">
					<span>Dobrý deň 👋 </span>
					<button 
						onClick={() => setShowBubble(false)} 
						className="ml-2 text-gray-500 hover:text-gray-700"
					>
						<X className="w-4 h-4" />
					</button>
				</div>
			)}
			{isChatOpen ? (
				<div className="w-80 h-96 flex flex-col bg-white rounded-lg shadow-2xl overflow-hidden">
					<div className="flex items-center justify-between p-4 border-b bg-blue-600 text-white">
							<div className="flex items-center gap-3">
								<div className="w-10 h-10 bg-white rounded-full flex items-center justify-center">
									<MessageSquare className="w-6 h-6 text-blue-600" />
								</div>
								<div>
									<h2 className="text-lg font-semibold">Online chat</h2>
									<p className="text-sm text-green-300">Online</p>
								</div>
							</div>
							<button onClick={() => setIsChatOpen(false)} className="text-white hover:text-gray-200 transition duration-300">
								<X className="w-5 h-5" />
							</button>
						</div>
						<div className="flex-grow overflow-hidden p-4" ref={scrollAreaRef}>
							<div className="h-full overflow-y-auto pr-4">
								{messages.map((message, index) => (
									<div
										key={index}
										className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'} mb-4`}
									>
										{message.sender === 'bot' && (
											<div className="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center mr-2">
												<MessageSquare className="w-4 h-4 text-gray-600" />
											</div>
										)}
										<div
											className={`rounded-lg p-3 max-w-[80%] ${
												message.sender === 'user'
													? 'bg-blue-600 text-white'
													: 'bg-gray-100 text-gray-900'
											}`}
										>
											{message.content}
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="p-4 border-t">
							<form onSubmit={handleSendMessage} className="flex gap-2">
								<input
									type="text"
									placeholder="Napíšte mi..."
									className="flex-grow px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
									value={input}
									onChange={(e) => setInput(e.target.value)}
								/>
								<button
									type="submit"
									className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
								>
									Odoslať
								</button>
							</form>
						</div>
				</div>
			) : (
				<button
					onClick={() => setIsChatOpen(true)}
					className="bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300"
				>
					<MessageSquare className="w-6 h-6" />
					<span className="sr-only">Open chat</span>
				</button>
			)}
		</div>
	)
}
