/* global fbq, hj */
import React, { useState, useEffect } from 'react';
import { Cookie, X } from 'lucide-react';

// Helper function to load external scripts
const loadScript = (src, id) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve();
      return;
    }
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

// Helper function to manage scripts based on consent
const manageScripts = (cookiePreferences) => {
  // Google Analytics
  if (cookiePreferences.Analytické) {
    loadScript('https://www.googletagmanager.com/gtag/js?id=YOUR-GA-ID', 'google-analytics')
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'YOUR-GA-ID');
      })
      .catch(error => console.error('Error loading Google Analytics:', error));
  } else {
    // Remove script if consent is withdrawn
    const script = document.getElementById('google-analytics');
    if (script) script.remove();
    // Clear any existing cookies
    document.cookie = '_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = '_gat=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = '_gid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  // Marketing scripts (e.g., Facebook Pixel)
  if (cookiePreferences.Marketingové) {
    loadScript('https://connect.facebook.net/en_US/fbevents.js', 'facebook-pixel')
      .then(() => {
        window.fbq = window.fbq || function() {
          (window.fbq.q = window.fbq.q || []).push(arguments);
        };
        window.fbq('init', 'YOUR-PIXEL-ID');
        window.fbq('track', 'PageView');
      })
      .catch(error => console.error('Error loading Facebook Pixel:', error));
  } else {
    const script = document.getElementById('facebook-pixel');
    if (script) script.remove();
  }

  // Performance monitoring (e.g., Hotjar)
  if (cookiePreferences.Výkonnostné) {
    loadScript('https://static.hotjar.com/c/hotjar-xxx.js?sv=x', 'hotjar')
      .then(() => {
        window.hj = window.hj || function() {
          (window.hj.q = window.hj.q || []).push(arguments);
        };
        window.hj('init', 'YOUR-HOTJAR-ID');
      })
      .catch(error => console.error('Error loading Hotjar:', error));
  } else {
    const script = document.getElementById('hotjar');
    if (script) script.remove();
  }
};

export default function CookieConsent() {
  const [isOpen, setIsOpen] = useState(false);
  const [cookies, setCookies] = useState({
    Nevyhnutné: true,
    Funkčné: false,
    Analytické: false,
    Výkonnostné: false,
    Marketingové: false
  });

  useEffect(() => {
    const savedPreferences = localStorage.getItem('cookiePreferences');
    if (savedPreferences) {
      const parsedPreferences = JSON.parse(savedPreferences);
      setCookies(parsedPreferences);
      manageScripts(parsedPreferences);
    } else {
      setIsOpen(true);
    }
  }, []);

  const handleAcceptAll = () => {
    const allAccepted = {
      Nevyhnutné: true,
      Funkčné: true,
      Analytické: true,
      Výkonnostné: true,
      Marketingové: true
    };
    localStorage.setItem('cookiePreferences', JSON.stringify(allAccepted));
    setCookies(allAccepted);
    manageScripts(allAccepted);
    setIsOpen(false);
  };

  const handleRejectAll = () => {
    const allRejected = {
      Nevyhnutné: true,
      Funkčné: false,
      Analytické: false,
      Výkonnostné: false,
      Marketingové: false
    };
    localStorage.setItem('cookiePreferences', JSON.stringify(allRejected));
    setCookies(allRejected);
    manageScripts(allRejected);
    setIsOpen(false);
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookiePreferences', JSON.stringify(cookies));
    manageScripts(cookies);
    setIsOpen(false);
  };

  const handleToggle = (type) => {
    if (type === 'Nevyhnutné') return;
    setCookies(prev => ({
      ...prev,
      [type]: !prev[type]
    }));
  };

  if (!isOpen) {
    return (
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-4 left-4 z-50 bg-blue-600 hover:bg-blue-700 text-white p-3 rounded-full shadow-lg transition-all hover:scale-105"
        aria-label="Cookie Settings"
      >
        <Cookie size={24} />
      </button>
    );
  }

  return (
    <div className="fixed bottom-4 left-4 z-50">
      <div className="bg-white rounded-xl shadow-xl w-[480px] max-w-[calc(100vw-2rem)] max-h-[480px] flex flex-col animate-slide-up">
        <div className="p-4 border-b border-gray-200 flex-shrink-0">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Cookie className="text-blue-600" size={20} />
              <h2 className="text-lg font-semibold">Nastavenia cookies</h2>
            </div>
            <button
              onClick={() => setIsOpen(false)}
              className="text-gray-500 hover:text-gray-700 transition-colors"
            >
              <X size={20} />
            </button>
          </div>
          <p className="mt-2 text-gray-600 text-sm">
            Táto webstránka používa cookies na zlepšenie vášho zážitku z prehliadania. Používaním našej stránky súhlasíte s našimi{' '}
            <a 
              href="/privacy-policy" 
              className="text-blue-600 hover:text-blue-800 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Zásadami ochrany osobných údajov
            </a>
            . Svoje preferencie môžete kedykoľvek upraviť.
          </p>
        </div>

        <div className="overflow-y-auto flex-grow scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
          <div className="p-4 space-y-4">
            {Object.entries(cookies)
              .map(([key, value]) => (
                <div key={key} className="flex items-start justify-between gap-4">
                  <div className="flex-1">
                    <h3 className="font-semibold capitalize text-sm">{key}</h3>
                    <p className="text-xs text-gray-600 mt-0.5">
                      {key === 'Nevyhnutné' ? 
                        'Nevyhnutné cookies sú absolútne potrebné pre správne fungovanie webstránky.' :
                        key === 'Funkčné' ?
                        'Funkčné cookies pomáhajú so základnou funkcionalitou a vylepšujú váš zážitok z používania webstránky.' :
                        key === 'Analytické' ? 
                        'Analytické cookies nám pomáhajú pochopiť, ako návštevníci používajú našu webstránku.' :
                        key === 'Výkonnostné' ?
                        'Výkonnostné cookies nám pomáhajú sledovať a vylepšovať výkon webstránky.' :
                        'Marketingové cookies nám umožňujú zobrazovať relevantné reklamné oznámenia.'}
                    </p>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer flex-shrink-0">
                    <input
                      type="checkbox"
                      checked={value}
                      onChange={() => handleToggle(key)}
                      disabled={key === 'Nevyhnutné'}
                      className="sr-only peer"
                    />
                    <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              ))}
          </div>
        </div>

        <div className="p-4 border-t border-gray-200 flex gap-2 justify-end flex-shrink-0">
          <button
            onClick={handleRejectAll}
            className="px-3 py-1.5 border border-gray-300 rounded-lg text-sm font-medium hover:bg-gray-50 transition-colors"
          >
            Odmietnuť všetky
          </button>
          <button
            onClick={handleSavePreferences}
            className="px-3 py-1.5 border border-gray-300 rounded-lg text-sm font-medium hover:bg-gray-50 transition-colors"
          >
            Uložiť moje preferencie
          </button>
          <button
            onClick={handleAcceptAll}
            className="px-3 py-1.5 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors"
          >
            Akceptovať všetky
          </button>
        </div>
      </div>
    </div>
  );
} 