import React from 'react'
import { Link } from 'react-router-dom'

export default function Header({ onOpenForm }) {
  return (
    <header className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="w-3 h-3 bg-orange-500 rounded-full animate-pulse" />
          <div className="w-3 h-3 bg-blue-500 rounded-full animate-pulse" />
          <Link to="/" className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-orange-500 hover:from-orange-500 hover:to-blue-600 transition duration-300 ease-in-out transform hover:scale-105">
            Čistíme ORL
          </Link>
        </div>
        <nav className="flex space-x-4">
          <Link to="/blog" className="px-3 py-2 rounded-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 transition duration-300 ease-in-out">Blog</Link>
          <Link to="/about" className="px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-900 transition duration-300 ease-in-out">O nás</Link>
          <Link to="/contact" className="px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-900 transition duration-300 ease-in-out">Kontakt</Link>
          <button onClick={onOpenForm} className="px-3 py-2 rounded-md text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 transition duration-300 ease-in-out transform hover:scale-105">Rezervácia</button>
        </nav>
      </div>
    </header>
  )
}
