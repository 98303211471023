import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './components/Header'
import Hero from './components/Hero'
import Services from './components/Services'
import Footer from './components/Footer'
import ChatWidget from './components/ChatWidget'
import Form from './components/form'
import ConfirmationModal from './components/ConfirmationModal'
import Blog from './components/Blog'
import Contact from './components/contact'  // Import the new Contact component
import AboutUs from './components/aboutUs'  // Import the new AboutUs component
import BlogPost from './components/blogPost';  // Import the new BlogPost component
import ScrollToTop from './components/ScrollToTop'  // Import the new ScrollToTop component
import CookieConsent from './components/CookieConsent'  // Add this import

export default function App() {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formResetKey, setFormResetKey] = useState(0);

  const handleOpenForm = () => {
    setFormResetKey(prevKey => prevKey + 1); // Increment the key to trigger form reset
    setIsFormOpen(true);
  };
  const handleCloseForm = () => setIsFormOpen(false);

  const handleOpenConfirmationModal = () => setIsConfirmationModalOpen(true);
  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    handleCloseForm();
  };

  return (
    <Router>
      <ScrollToTop /> {/* Add ScrollToTop component here */}
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <Header onOpenForm={handleOpenForm} />
        <Routes>
          <Route path="/" element={
            <main className="flex-grow">
              <Hero onOpenForm={handleOpenForm} />
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <Services />
              </div>
            </main>
          } />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/contact" element={<Contact />} />  {/* Add new route for Contact page */}
          <Route path="/about" element={<AboutUs />} />  {/* Add new route for AboutUs page */}
          
        </Routes>
        <Footer />
        <ChatWidget />
        <Form 
          key={formResetKey}
          isOpen={isFormOpen} 
          onClose={handleCloseForm} 
          onSubmitSuccess={handleOpenConfirmationModal}
        />
        {isConfirmationModalOpen && (
          <ConfirmationModal onClose={handleCloseConfirmationModal} />
        )}
        <CookieConsent />
      </div>
    </Router>
  )
}
