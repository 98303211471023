import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const blogPosts = [
  {
    id: 1,
    title: "5 najčastejších mýtov o čistení odlučovačov ropných látok",
    excerpt: "Objasnenie bežných mylných predstáv o údržbe odlučovačov ropných látok a prečo je pravidelné čistenie kľúčové.",
    date: "2023-07-01",
    category: "Údržba",
    image: "/pics_blog/caste_myty.jpg",
    content: `
    <h2>Úvod</h2>
    <p>
      Odlučovače ropných látok sú kľúčovým zariadením pre priemyselné a komerčné prevádzky, ktoré pracujú s ropnými produktmi, resp. v ktorých priestoroch kde parkujú autá, v priestoroch kde sa nakladá s ropnými látkami. Tieto zariadenia zohrávajú neoceniteľnú úlohu pri ochrane životného prostredia a pri dodržiavaní legislatívnych noriem. Čistenie odlučovačov je však často predmetom rôznych mylných predstáv. V tomto článku sa pozrieme na najčastejšie mýty o čistení odlučovačov ropných látok a objasníme, ako to skutočne funguje.
    </p>

    <h2>Mýtus 1: Odlučovač ropných látok stačí čistiť raz ročne</h2>
    <p>
      Jedným z najrozšírenejších mýtov je, že odlučovače ropných látok potrebujú čistenie iba raz ročne. Pravda je, že frekvencia čistenia závisí od typu prevádzky a množstva znečistenia, ktoré odlučovač zachytáva. Intenzívne prevádzky, ako sú čerpacie stanice alebo parkoviská veľkých obchodných centier, môžu vyžadovať čistenie až každé tri mesiace. Nedostatočná údržba môže viesť k preťaženiu odlučovača a k jeho zníženej účinnosti.
    </p>

    <h2>Mýtus 2: Ak odlučovač nepretečie, nepotrebuje čistenie</h2>
    <p>
      Mnohí prevádzkovatelia si myslia, že odlučovač netreba čistiť, ak ešte nepretečie. Pretečenie je však posledným štádiom zanedbania údržby. Ak je odlučovač naplnený ropnými látkami a kalom, jeho účinnosť môže byť výrazne znížená dávno predtým, ako dôjde k pretečeniu. Pravidelné kontroly a údržba zabezpečia, že zariadenie bude fungovať správne a že nedôjde k náhlemu zlyhaniu.
    </p>

    <h2>Mýtus 3: Čistenie odlučovača môžeme urobiť sami</h2>
    <p>
      Niektorí prevádzkovatelia si myslia, že čistenie odlučovača ropných látok je jednoduchá úloha, ktorú môžu zvládnuť sami. Pravdou však je, že profesionálne čistenie si vyžaduje špeciálne vybavenie a odborné znalosti. Profesionálna spoločnosť disponuje nástrojmi, ktoré dokážu bezpečne odstrániť nahromadený olej, tuky a kal, a zároveň zabezpečí správne zneškodnenie nebezpečných látok v súlade s legislatívou.
    </p>

    <h2>Mýtus 4: Nový odlučovač nepotrebuje údržbu</h2>
    <p>
      Nové odlučovače ropných látok sú samozrejme v bezchybnom stave, ale to neznamená, že nevyžadujú údržbu. Každý odlučovač potrebuje pravidelné kontroly a čistenie, aby si udržal svoju účinnosť. Aj nový odlučovač sa môže rýchlo zanášať v závislosti od množstva zachytených ropných látok a kalu. Odporúča sa naplánovať prvú kontrolu niekoľko mesiacov po inštalácii.
    </p>

    <h2>Mýtus 5: Čistenie odlučovačov je drahé a zbytočné</h2>
    <p>
      Čistenie odlučovačov ropných látok môže na prvý pohľad vyzerať ako dodatočný náklad, ale v skutočnosti ide o investíciu, ktorá sa vyplatí. Pravidelná údržba predlžuje životnosť odlučovača, znižuje riziko porúch a pretečenia a zároveň chráni životné prostredie. Ak sa údržba zanedbá, náklady na opravy alebo ekologické havárie môžu byť oveľa vyššie.
    </p>

    <h2>Záver</h2>
    <p>
      Je dôležité rozpoznať časté mýty o čistení odlučovačov ropných látok a porozumieť, že pravidelná údržba je kľúčová pre ich bezchybnú prevádzku. Nespoliehajte sa na mylné predstavy – dbajte na pravidelné čistenie a kontroly, aby váš odlučovač fungoval efektívne a aby ste sa vyhli zbytočným problémom. Ak potrebujete odborné čistenie alebo konzultáciu, neváhajte nás kontaktovať.
    </p>
    `,
  },
  {
    id: 9,
    title: "Čo je odlučovač ropných látok a aký má význam pre vašu prevádzku",
    excerpt: "Odlučovač ropných látok je kľúčové zariadenie pre mnohé priemyselné odvetvia. Zistite, ako môže benefitovať vašu prevádzku.",
    date: "2023-09-15",
    category: "Čo to je ORL?",
    image: "/pics_blog/co_je_odlucovac.jpg",  // Make sure this image exists in your public folder
    content: `
    <article>
            
      <section>
        
        <p>
          V dnešnom priemyselnom prostredí je kontrola a správa odpadových vôd nevyhnutná pre ochranu životného prostredia a dodržiavanie regulačných požiadaviek. Jedným z kľúčových zariadení, ktoré pomáha dosiahnuť tieto ciele, je odlučovač ropných látok.
        </p>
      </section>
      <h2>Čo je odlučovač ropných látok?</h2>
      <section>
        
        <p>
          Odlučovač ropných látok je zariadenie navrhnuté na separáciu ropných látok (ako sú oleje, tuky a nafta) z odpadových vôd. Tento proces je nevyhnutný v mnohých priemyselných odvetviach, ako je automobilový priemysel, potravinárstvo, petrochemický priemysel a mnoho ďalších.
        </p>
      </section>
      <h2>Ako funguje odlučovač ropných látok?</h2>
      <section>
        
        <p>
          Odlučovače využívajú rôzne fyzikálne princípy na separáciu ropných látok od vody. Medzi najčastejšie používané metódy patrí gravitačná separácia, kde sa kvapaliny oddelia na základe rozdielnej hustoty, alebo technologické metódy ako flotácia či membránové filtračné systémy. Moderné odlučovače často kombinujú viacero týchto techník na dosiahnutie vyššej efektivity.
        </p>
      </section>
 
      <h2>Význam odlučovačov ropných látok</h2>
      
        
        <ul>
          <li>
            <strong>Ochrana životného prostredia:</strong> Správne fungujúci odlučovač zabraňuje kontaminácii vodných zdrojov ropnými látkami, čím chráni ekosystémy a biodiverzitu.
          </li>
          <li>
            <strong>Regulačné požiadavky:</strong> Mnohé krajiny majú prísne normy týkajúce sa kvality odpadových vôd. Odlučovače pomáhajú firmám dodržiavať tieto predpisy a vyhnúť sa pokutám.
          </li>
          <li>
            <strong>Ekonomická efektívnosť:</strong> Oddelením a recykláciou ropných látok môžu firmy znížiť náklady na ich nákup a likvidáciu.
          </li>
          <li>
            <strong>Zlepšenie prevádzkovej efektivity:</strong> Čisté odlučovače zaisťujú hladký chod technologických procesov a minimalizujú prestoje spôsobené nečistotami v systéme.
          </li>
        </ul>
      
      </br>
      <section>
        
        <p>

          Odlučovače ropných látok sú neoddeliteľnou súčasťou moderných priemyselných prevádzok. Investícia do kvalitného odlučovacieho systému a jeho pravidelná údržba prináša nielen environmentálne, ale aj ekonomické a prevádzkové výhody. Ak hľadáte spoľahlivého partnera pre čistenie a údržbu vašich odlučovačov ropných látok, neváhajte nás kontaktovať a zistite, ako môžeme pomôcť optimalizovať vaše procesy a zabezpečiť ich dlhodobú efektivitu.
        </p>
      </section>
    </article>
    `,
  },
  {
    id: 8,
    title: "Prečo je pravidelné čistenie odlučovača ropných látok kľúčové pre váš biznis",
    excerpt: "Zistite, prečo je pravidelná údržba odlučovačov ropných látok nevyhnutná pre váš podnik a životné prostredie.",
    date: "2023-09-01",
    category: "držba",
    image: "/pics_blog/preco_cleaning.jpg",
    content: `
    <h2>Úvod</h2>
    <p>
      Ak prevádzkujete obchodné alebo retail centrum, garážový dom, čerpaciu stanicu alebo akýkoľvek iný podnik s veľkým pohybom vozidiel, určite už poznáte význam odlučovačov ropných látok. Tieto zariadenia chránia životné prostredie a pomáhajú dodržiavať legislatívne požiadavky týkajúce sa nakladania s odpadovými vodami. Avšak nestačí ich len inštalovať – pravidelné čistenie a údržba sú nevyhnutné pre zaistenie ich správnej funkčnosti a pre ochranu vášho biznisu pred možnými problémami.
    </p>

    <h2>Pre koho je čistenie odlučovača obzvlášť dôležité?</h2>
    <h3>1. Parkoviská obchodných centier a retailových prevádzok</h3>
    <p>
      Na parkoviskách obchodných a retail centier sa zhromažďuje veľké množstvo vozidiel. Ropné látky, oleje a palivové zvyšky môžu znečistiť odpadovú vodu a spôsobiť závažné environmentálne škody, ak nie sú správne zachytené a oddelené. Pravidelné čistenie odlučovača ropných látok je pre tieto prevádzky nevyhnutné, pretože:
    </p>
    <ul>
      <li><strong>Minimalizuje riziko znečistenia vodných tokov</strong> – Znečistenie odpadovej vody môže viesť k ekologickým haváriám, za ktoré môže byť vaša spoločnosť zodpovedná.</li>
      <li><strong>Zvyšuje bezpečnosť parkoviska</strong> – Olejové škvrny a iné nečistoty môžu vytvárať nebezpečné povrchy, na ktorých môže dôjsť k úrazom návštevníkov.</li>
    </ul>

    <h3>2. Garážové domy</h3>
    <p>
      V garážových domoch je vysoká koncentrácia vozidiel, čo znamená, že riziko úniku ropných látok je tiež vysoké. Nahromadené oleje a tuky môžu znečistiť okolitú pôdu a vodu, ale aj poškodiť infraštruktúru objektu. Pravidelná údržba odlučovačov pomáha udržať garážové domy bezpečné a čisté, pričom zároveň predchádza zbytočným výdavkom na sanáciu.
    </p>

    <h3>3. Čerpacie stanice</h3>
    <p>
      Čerpacie stanice sú jedným z najčastejších miest, kde dochádza k úniku ropných látok, palív a mazív. Bez pravidelnej údržby odlučovačov sa tieto nebezpečné látky môžu dostať do kanalizačného systému a znečistiť životné prostredie. Okrem toho, čerpacie stanice podliehajú prísnym legislatívnym nariadeniam, a preto je kľúčové, aby boli odlučovače ropných látok vždy v perfektnom stave.
    </p>

    <h3>4. Priemyselné a logistické areály</h3>
    <p>
      Logistické centrá a priemyselné objekty majú často rozsiahle vonkajšie plochy, kde dochádza k častému pohybu nákladných a osobných vozidiel. Znečistenie ropnými produktmi môže mať vážny dopad na okolité prostredie a môže viesť k vysokým sankciám, ak nie sú prijaté opatrenia na jeho elimináciu.
    </p>

    <h2>Prečo je pravidelné čistenie rozhodujúce?</h2>
    <h3>1. Predchádzanie zlyhaniu odlučovača</h3>
    <p>
      Keď sa odlučovač plní ropnými látkami a nečistotami, jeho účinnosť klesá. Ak sa čistenie zanedbá, môže dôjsť k pretečeniu odlučovača a ropné látky sa dostanú do kanalizačného systému alebo dokonca do prírodných vodných zdrojov. To môže viesť k vysokým pokutám a poškodeniu vašej reputácie.
    </p>

    <h3>2. Plnenie legislatívnych požiadaviek</h3>
    <p>
      Všetky prevádzky, ktoré pracujú s odpadovými vodami, musia dodržiavať miestne a národné predpisy týkajúce sa vypúšťania vody. Nesplnenie týchto nariadení môže viesť k právnym sankciám. Pravidelné čistenie odlučovača zabezpečí, že vaša prevádzka vždy spĺňa požiadavky zákona a minimalizuje riziko pokút.
    </p>

    <h3>3. Ekonomické výhody</h3>
    <p>
      Prevencia je vždy lacnejšia ako riešenie následkov. Pravidelná údržba odlučovača ropných látok môže predĺžiť jeho životnos a znížiť náklady na opravy alebo výmenu. Zároveň sa vyhnete vysokým nákladom spojeným so sanáciou znečisteného životného prostredia.
    </p>

    <h2>Ako často by sa mali odlučovače čistiť?</h2>
    <p>
      Frekvencia čistenia odlučovača ropných látok závisí od mnohých faktorov, ako je intenzita prevádzky, veľkosť odlučovača a množstvo odpadovej vody. Odporúča sa, aby sa odlučovače kontrolovali a čistili minimálne dvakrát ročne, ale v prípade čerpacích staníc a veľkých parkovísk môže byť potrebné častejšie čistenie.
    </p>

    <h2>Záver</h2>
    <p>
      Pravidelné čistenie odlučovača ropných látok nie je len legislatívnou povinnosťou, ale aj kľúčovým opatrením na ochranu vášho biznisu a životného prostredia. Nezáleží na tom, či prevádzkujete obchodné centrum, garážový dom alebo čerpaciu stanicu – pravidelná údržba vám pomôže predísť problémom a udržať vašu prevádzku bezpečnú a efektívnu. Ak potrebujete profesionálne čistenie odlučovača, neváhajte nás kontaktovať.
    </p>
    `,
  },
  {
    id: 7,
    title: "Top 5 príznakov, že váš odlučovač ropných látok potrebuje čistenie",
    excerpt: "Zistite, kedy je správny čas na čistenie vášho odlučovača ropných látok a ako predísť vážnym problémom.",
    date: "2023-08-15",
    category: "Údržba",
    image: "/pics_blog/pat_priznakov.jpeg",
    content: `
    <h2>Úvod</h2>
    <p>
      Odlučovač ropných látok je kľúčovým zariadením pre každú prevádzku, ktorá prichádza do kontaktu s ropnými produktmi. Či už ide o parkoviská obchodných centier, čerpacie stanice alebo priemyselné areály, odlučovače hrajú nepostrádateľnú úlohu v ochrane životného prostredia a plnení legislatívnych požiadaviek. Avšak rovnako ako každé iné zariadenie, aj odlučovače potrebujú pravidelnú údržbu a čistenie. Ako však zistiť, kedy je ten správny čas? Tu sú top 5 príznakov, že váš odlučovač ropných látok potrebuje čistenie.
    </p>

    <h2>1. Znížená účinnosť odlučovania ropných látok</h2>
    <p>
      Jedným z hlavných indikátorov, že váš odlučovač ropných látok potrebuje údržbu, je znížená schopnosť odlučovať ropné látky z odpadových vôd. Ak si všimnete, že voda, ktorá odchádza z odlučovača, obsahuje viditeľné stopy oleja alebo iných ropných produktov, je to jasný znak, že odlučovač je preťažený a potrebuje čistenie.
    </p>

    <h2>2. Pretečenie odlučovača</h2>
    <p>
      Ak odlučovač ropných látok nie je pravidelne čistený, môže dôjsť k jeho pretečeniu. Nahromadené oleje, kal a iné znečisťujúce látky spôsobujú, že odlučovač už nie je schopný zachytiť nové ropné látky, čo vedie k jeho pretečeniu a úniku kontaminovanej vody do kanalizácie alebo prírody. Pretečenie môže spôsobiť vážne environmentálne problémy a porušenie zákona.
    </p>

    <h2>3. Nepríjemný zápach</h2>
    <p>
      Jedným z častých signálov, že odlučovač potrebuje čistenie, je nepríjemný zápach. Ak z okolia odlučovača cítite silný zápach oleja, ropy alebo hnilobných látok, môže to byť spôsobené nahromadením zvyškov, ktoré sa rozkladajú. Tento zápach nielenže narúša pracovné prostredie, ale môže tiež signalizovať nebezpečenstvo pre zdravie.
    </p>

    <h2>4. Zvýšené hladiny kalu a oleja</h2>
    <p>
      Odlučovače ropných látok sú navrhnuté tak, aby zachytávali kal a olej, ktoré sa usadzujú na dne a povrchu odlučovača. Ak pri kontrole odlučovača zistíte, že hladiny kalu a oleja sú výrazne vyššie, je to jasný znak, že odlučovač je preťažený a potrebuje čistenie. Nahromadené látky môžu výrazne znížiť účinnosť odlučovača a spôsobiť jeho poškodenie.
    </p>

    <h2>5. Výpadky v prevádzke alebo výstražné signály</h2>
    <p>
      Moderné odlučovače ropných látok môžu byť vybavené senzormi, ktoré monitorujú hladiny oleja, kalu a ďalších látok. Ak váš odlučovač vydáva výstražné signály alebo sa opakovane vyskytujú problémy s jeho prevádzkou, je to jasný indikátor, že potrebuje odbornú kontrolu a čistenie. Ignorovanie týchto varovaní môže viesť k vážnym problémom a vysokým nákladom na opravy.
    </p>

    <h2>Záver</h2>
    <p>
      Pravidelné čistenie a údržba odlučovača ropných látok sú nevyhnutné na zabezpečenie jeho bezchybnej prevádzky. Ak zaznamenáte ktorýkoľvek z týchto príznakov, neváhajte a zabezpečte čistenie čo najskôr. Tým nielen predídete problémom, ale zároveň ochránite životné prostredie, splníte legislatívne požiadavky, neudelia vám pokutu, prípadne Vám nebude hroziť trest za zanedbanie povinností. Ak potrebujete odbornú pomoc pri čistení odlučovača ropných látok, kontaktujte nás ešte dnes.
    </p>
    `,
  },
  {
    id: 6,
    title: "Ako čistenie odlučovačov ropných látok zvyšuje efektivitu vašej prevádzky",
    excerpt: "Zistite, ako pravidelná údržba ORL môže zlepšiť výkonnosť vašej prevádzky a priniesť významné úspory",
    date: "2023-08-10",
    category: "Efektivita",
    image: "/pics_blog/cistenie.jpg",
    content: `
    <h2>Úvod</h2>
    <p>
      V modernom priemysle je efektívnosť prevádzky kľúčovým faktorom pre dosiahnutie úspechu a konkurencieschopnosti. Jedným z dôležitých aspektov, ktorý často prehliadajú mnohé podniky, je údržba a čistenie odlučovačov ropných látok. Tento článok vám predstaví, ako pravidelné čistenie odlučovačov ropných látok môže významne zvýšiť efektivitu vašej prevádzky a priniesť ďalšie výhody.
    </p>

    <h2>Čo sú odlučovače ropných látok?</h2>
    <p>
      Odlučovače ropných látok sú zariadenia navrhnuté na separáciu ropných látok a iných nečistôt z odpadových vôd. Tieto systémy sú nevyhnutné v rôznych priemyselných odvetviach, ako sú automobilový priemysel, potravinárstvo, farmaceutika a ďalšie, kde je potrebné zabezpečiť čistotu a kvalitu odpadových vôd pred ich vypustením do životného prostredia.
    </p>

    <h2>Význam čistenia odlučovačov ropných látok</h2>
    <h2>Zvýšenie efektivity procesov</h2>
    <p>
      Pravidelné čistenie odlučovačov ropných látok zabezpečuje, že zariadenie funguje na optimálnej úrovni. Zanesené filtračné médiá a usadeniny môžu spôsobiť zníženie výkonu, čo vedie k zvýšeniu prevádzkových nákladov a zníženiu produktivity. Čistenie odstráni tieto nečistoty, čím obnoví plnú kapacitu odlučovača a zlepší celkový výkon systému.
    </p>

    <h2>Predĺženie životnosti zariadení</h2>
    <p>
      Investícia do pravidelnej údržby odlučovačov ropných látok môže výrazne predĺžiť ich životnosť. Zanesenie a korózia sú bežné problémy, ktoré môžu viesť k častým poruchám a nutnosti nákladných opráv alebo výmeny zariadení. Čistenie pomáha predchádzať týmto problémom tým, že udržiava rozhodujúce komponenty v dobrom stave.
    </p>

    <h2>Zníženie energetických nákladov</h2>
    <p>
      Efektívne fungujúci odlučovač ropných látok spotrebuje menej energie v porovnaní s neudržiavanými systémami, ktoré musia pracovať tvrdšie na dosiahnutie rovnakých výsledkov. Optimalizácia výkonu zariadenia cez pravidelné čistenie môže viesť k významným úsporám na energetických nákladoch.
    </p>

    <h2>Ekologické výhody čistenia odlučovačov ropných látok</h2>
    <h2>Zlepšenie kvality odpadových vôd</h2>
    <p>
      Čistenie odlučovačov ropných látok priamo prispieva k zlepšeniu kvality odpadových vôd, ktoré vaše zariadenie vypúšťa do životného prostredia. To nielenže pomáha chrániť prírodné zdroje, ale tiež zabezpečuje, že vaša prevádzka spĺňa všetky environmentálne predpisy a normy, čím sa vyhnete pokutám a právnym problémom.
    </p>

    <h2>Zníženie ekologickej stopy</h2>
    <p>
      Efektívnejšie spracovanie odpadových vôd znamená menšiu ekologickú stopu vašej prevádzky. Minimalizácia únikov ropných látok a znečistenia vody prispieva k udržateľnému rozvoju a zodpovednému podnikaniu, čo môže pozitívne ovplyvniť reputáciu vašej firmy.
    </p>

    <h2>Návratnosť investícií do čistenia</h2>
    <h2>Nižšie prevádzkové náklady</h2>
    <p>
      Aj keď môže čistenie odlučovačov ropných látok predstavovať počiatočné náklady, dlhodobé úspory v podobe nižších prevádzkových nákladov, menších oprav a predĺženej životnosti zariadení z nej robia výhodnú investíciu.
    </p>

    <h2>Zvýšenie produktivity</h2>
    <p>
      Optimalizovaný výkon odlučovača ropných látok umožňuje plynulejšiu a efektívnejšiu prevádzku, čo môže viesť k zvýšeniu celkovej produktivity vašej firmy. Menej prestojov a porúch znamená, že vaše zariadenia pracujú viac času a s vyššou efektivitou.
    </p>

    <h2>Praktické kroky pre efektívne čistenie odlučovačov ropných látok</h2>
    <p>
      • Pravidelný plán údržby: Vytvorte a dodržujte plán pravidelného čistenia a údržby vašich odlučovačov ropných látok.<br>
      • Použitie kvalitných čistiacich prostriedkov: Investujte do špeciálnych čistiacich prostriedkov, ktoré sú účinné a bezpečné pre vaše zariadenie.<br>
      • Školenie personálu: Zabezpečte, aby váš personál bol riadne vyškolený na správne postupy čistenia a údržby.<br>
      • Monitorovanie výkonu: Pravidelne sledujte výkon odlučovačov ropných látok, aby ste identifikovali prípadné problémy včas.
    </p>

    <h2>Záver</h2>
    <p>
      Čistenie odlučovačov ropných látok je neoddeliteľnou súčasťou udržateľnej a efektívnej prevádzky. Zvýšenie efektivity procesov, predĺženie životnosti zariadení, zníženie energetických nákladov a ekologické výhody sú len niektoré z mnohých dôvodov, prečo by ste mali venovať pozornosť pravidelnej údržbe týchto systémov. Investícia do čistenia odlučovačov ropných látok sa vám určite vráti v podobe vyššej produktivity, nižších nákladov a zodpovedného podnikania.
    </p>
    `,
  },
  {
    id: 5,
    title: "Ako často by ste mali čistiť svoj odlučovač ropných látok?",
    excerpt: "Zistite optimálnu frekvenciu čistenia vášho odlučovača ropných látok pre rôzne typy prevádzok a prečo je to dôležité.",
    date: "2023-08-01",
    category: "Údržba",
    image: "/pics_blog/ako_casto_clean.jpg",
    content: `
    <h2>Úvod</h2>
    <p>
      Odlučovače ropných látok sú nevyhnutné pre prevádzky, ktoré prichádzajú do kontaktu s nebezpečnými látkami, ako sú oleje a ropné produkty. Správne fungovanie týchto zariadení závisí nielen od ich inštalácie, ale aj od pravidelného čistenia a údržby. Ako často by ste však mali čistiť svoj odlučovač? To závisí od niekoľkých faktorov, ktoré si v tomto článku podrobne rozoberieme.
    </p>

    <h2>1. Frekvencia čistenia v závislosti od typu prevádzky</h2>
    <p>
      Frekvencia čistenia odlučovača ropných látok môže byť rôzna v závislosti od toho, v akej prevádzke je nainštalovaný.
    </p>
    <ul>
      <li><strong>Bežné prevádzky:</strong> V prípade, že vaša prevádzka nezahŕňa častý kontakt s ropnými produktmi (napr. menšie parkoviská alebo administratívne budovy), odporúča sa čistenie odlučovača minimálne dvakrát ročne. To zaručí, že odlučovač zostane plne funkčný a nedôjde k jeho preťaženiu.</li>
      <li><strong>Intenzívne prevádzky:</strong> Pre čerpacie stanice, veľké parkoviská, priemyselné areály a logistické centrá, kde je vysoké riziko únikov ropných látok, sa odporúča čistenie odlučovačov každé 3 mesiace. Tieto prevádzky pracujú s veľkým objemom odpadových vôd, ktoré môžu obsahovať vysoké koncentrácie nečistôt a ropných produktov, čo urýchľuje proces zanášania odlučovača.</li>
    </ul>

    <h2>2. Pravidelné kontroly a monitorovanie</h2>
    <p>
      Odporúča sa, aby sa odlučovače ropných látok kontrolovali minimálne každý štvrťrok. Aj keď je systém funkčný, je dôležité monitorovať hladinu kalu a oleja v odlučovači. Ak hladina presiahne určité hodnoty, zariadenie môže stratiť svoju účinnosť, čo môže viesť k pretečeniu alebo úniku nečistôt do kanalizácie či do prírodných vodných tokov. Takéto zlyhanie môže mať nielen finančné, ale aj právne následky.
    </p>

    <h2>3. Legislatívne požiadavky</h2>
    <p>
      Podľa Zákona o vodách (č. 364/2004 Z.z.) sú všetky prevádzky povinné zabezpečiť, aby vody vypúšťané do kanalizácie alebo prírodných tokov spĺňali predpísané normy. Pravidelné čistenie odlučovačov je kľúčové na splnenie týchto legislatívnych požiadaviek. Nesprávne udržiavané odlučovače môžu spôsobiť únik nebezpečných látok, čo môže viesť k vysokým pokutám a poškodeniu reputácie vašej spoločnosti.
    </p>

    <h2>4. Ako čistenie ovplyvňuje prevádzkovú efektivitu a aká je výsledná cena</h2>
    <p>
      Zanesený odlučovač ropných látok nielen znižuje efektívnosť systému, ale zvyšuje aj spotrebu energie a môže viesť k drahým opravám. Pravidelné čistenie umožňuje odlučovaču pracovať na plný výkon, čím sa zvyšuje celková efektivita vašej prevádzky. Okrem toho sa znižujú prevádzkové náklady na energiu a údržbu, čím dochádza k šetreniu nákladov. V konečnom dôsledku, ak sa filtračný systém odlučovača ropných látok zanesie, bude to bolieť aj vašu peňaženku pretože o to finančne náročnejšie bude opätovne sfunkčniť odlučovač ropných látok a odstrániť rokmi nahromadený kal.
    </p>

    <h2>Záver</h2>
    <p>
      Ako často by ste mali čistiť svoj odlučovač ropných látok, závisí od charakteru vašej prevádzky. Bežným odporúčaním je čistenie minimálne dvakrát ročne, no intenzívne prevádzky môžu potrebovať čistenie každé tri mesiace. Pravidelná údržba nielen chráni životné prostredie a splňuje legislatívne normy, ale aj zvyšuje efektivitu a bezpečnosť vašej prevádzky. Ak potrebujete odborné poradenstvo alebo čistenie odlučovačov, neváhajte nás kontaktovať.
    </p>
    `,
  },
  {
    id: 4,
    title: "Profesionálne čistenie odlučovača ropných látok: Krok za krokom",
    excerpt: "Zistite, ako prebieha profesionálne čistenie odlučovača ropných látok a prečo je to dôležité pre vašu prevádzku.",
    date: "2023-07-30",
    category: "Údržba",
    image: "/pics_blog/krok.jpg",
    content: `
    <h2>Úvod</h2>
    <p>
      Pravidelné čistenie odlučovača ropných látok je nevyhnutné na zaistenie jeho efektívnej prevádzky. Zanesený odlučovač môže spôsobiť pretečenie, znížiť účinnosť odlučovania a dokonca viesť k porušeniu legislatívnych nariadení a následnému udeleniu pokuty, dokonca môžeme hovoriť aj o trestnoprávnej rovine. V tomto článku vám krok za krokom ukážeme, ako prebieha profesionálne čistenie odlučovača ropných látok, aby bol v optimálnom stave.
    </p>

    <h2>1. Príprava na čistenie</h2>
    <p>
      Pred samotným čistením je dôležité vykonať základnú prípravu:
    </p>
    <ul>
      <li><strong>Vypnutie zariadenia:</strong> Zabezpečte, aby bol odlučovač ropných látok bezpečne vypnutý (ak je napájaný) a odpojený od všetkých prívodov. Tento krok je nevyhnutný na predídenie úniku nebezpečných látok počas čistenia.</li>
      <li><strong>Bezpečnostné opatrenia:</strong> Ochrana pracovníkov je prvoradá. Je potrebné zabezpečiť používanie ochranných pomôcok, ako sú rukavice, ochranné okuliare a dýchacie masky, pretože odlučovače môžu obsahovať nebezpečné chemikálie. Odporúčame aby údržbu vykonávali minimálne dve osoby v prípade potreby okamžitého poskytnutia prvej pomoci.</li>
    </ul>

    <h2>2. Odčerpanie nahromadeného oleja a kalu</h2>
    <p>
      Profesionálne čistenie začína odstránením nahromadených nečistôt:
    </p>
    <ul>
      <li><strong>Odčerpanie ropných látok:</strong> Pomocou špeciálnych čerpadiel sa odstraňujú ropné látky, tuky a kal z odlučovača. Tento krok je dôležitý na zabezpečenie správnej funkcie odlučovača, pretože hromadenie týchto látok môže spôsobiť zlyhanie systému.</li>
      <li><strong>Separácia odpadu:</strong> Znečisťujúce látky sa musia separovať na ropné látky a pevné častice, ktoré sa následne odvezú na zneškodnenie podľa platnej legislatívy. Tento proces je kľúčový pre ochranu životného prostredia a splnenie legislatívnych požiadaviek.</li>
    </ul>

    <h2>3. Čistenie filtračných jednotiek</h2>
    <p>
      Odlučovače ropných látok často obsahujú filtračné jednotky, ktoré zachytávajú drobné nečistoty a zvyšky oleja. Profesionáli tieto filtre:
    </p>
    <ul>
      <li><strong>Demontujú a čistia:</strong> Filtre sa demontujú a dôkladne vyčistia vysokotlakovou vodou alebo nahradia, ak sú príliš znečistené alebo poškodené. Tento krok zabezpečí, že odlučovač bude pracovať efektívne a zabezpečí maximálnu separáciu ropných látok.</li>
    </ul>

    <h2>4. Kontrola tesnenia a funkčnosti systému</h2>
    <p>
      Po odstránení nečistôt a vyčistení filtrov nasleduje kontrola tesnenia a celkového stavu odlučovača:
    </p>
    <ul>
      <li><strong>Skontrolovanie tesnenia:</strong> Je nevyhnutné skontrolovať všetky tesnenia, aby nedošlo k únikom. Akékoľvek poškodené časti sa musia vymeniť.</li>
      <li><strong>Testovanie funkčnosti:</strong> Po dokončení čistenia sa odlučovač opäť zapne (ak je to možné) a otestuje sa jeho funkčnosť. Skúma sa prietok, účinnosť odlučovania a správna činnosť všetkých komponentov.</li>
    </ul>

    <h2>5. Likvidácia odpadu</h2>
    <p>
      Profesionálna spoločnosť zabezpečí, že všetok odpad, ktorý vznikol počas čistenia, bude správne zlikvidovaný podľa legislatívnych požiadaviek na nebezpečný odpad. Tým sa zaručí, že nedôjde k ohrozeniu životného prostredia a že vaša prevádzka zostane v súlade s platnými zákonmi. 
    </p>

    <h2>Záver</h2>
    <p>
      Pravidelné čistenie odlučovača ropných látok je dôležitým krokom k zabezpečeniu jeho správnej funkčnosti, predĺženiu jeho životnosti a ochrane životného prostredia. Zanedbanie údržby môže viesť k vážnym environmentálnym problémom a právnym sankciám. Ak potrebujete profesionálne čistenie odlučovača ropných látok, neváhajte nás kontaktovať – naša spoločnosť vám zabezpečí komplexnú a bezpečnú údržbu vašich zariadení.
    </p>
    `,
  },
  {
    id: 2,
    title: "Výhody outsourcingu čistenia odlučovačov ropných látok",
    excerpt: "Objavte, prečo je outsourcing čistenia ORL výhodnou voľbou pre vašu prevádzku z hľadiska nákladov, efektivity a dodržiavania legislatívy.",
    date: "2023-07-15",
    category: "Údržba",
    image: "/pics_blog/Oursourcing.jpg",
    content: `
    <h2>Úvod</h2>
    <p>
      Odlučovače ropných látok (ORL) sú nevyhnutné pre prevádzky, ktoré manipulujú s ropnými produktmi, aby zabezpečili správnu ochranu životného prostredia a dodržanie legislatívnych požiadaviek. Údržba a čistenie týchto zariadení však môže byť časovo náročné, nákladné a vyžaduje odborné zručnosti. Preto sa čoraz viac firiem rozhoduje pre outsourcing čistenia odlučovačov ropných látok. V tomto článku sa pozrieme na hlavné výhody, ktoré outsourcing prináša vašej prevádzke.
    </p>

    <h2>1. Výrazná úspora nákladov na vybavenie a licencie</h2>
    <p>
      Jedným z hlavných dôvodov, prečo sa firmy rozhodujú pre outsourcing čistenia ORL, je vysoká cena špecializovaného vybavenia. Jazdené vozidlo určené na čistenie odlučovačov ropných látok môže stáť viac ako 100.000 EUR. To zahŕňa nielen samotné vozidlo, ale aj špeciálne čerpadlá a nádoby na odpad, ktoré sú potrebné pre bezpečné odčerpávanie a likvidáciu nečistôt. Pre väčšinu firiem je táto investícia príliš vysoká, najmä ak čistenie nie je ich hlavnou činnosťou.
    </p>
    <p>
      Navyše, na prevádzku takéhoto zariadenia sú potrebné špeciálne licencie a povolenia od príslušných orgánov. Tieto administratívne náklady môžu byť pre malú či strednú firmu výraznou záťažou. Outsourcingom čistenia sa však týmto nákladom môžete vyhnúť, pretože externá firma už disponuje potrebným vybavením aj licenciami.
    </p>

    <h2>2. Zníženie personálnych nákladov a povinností</h2>
    <p>
      Udržiavanie vlastného tímu na čistenie ORL môže byť nákladné. Zahrňuje to nielen mzdy, ale aj náklady spojené s odvádzaním sociálnych a zdravotných odvodov, školením zamestnancov a zabezpečením ich bezpečnosti pri práci. Navyše, zamestnanci musia byť špecializovaní na údržbu týchto zariadení, čo si vyžaduje pravidelné školenia a certifikácie.
    </p>
    <p>
      Outsourcing čistenia ORL vám umožňuje minimalizovať tieto náklady a povinnosti, pretože externá firma zabezpečuje všetky aspekty čistenia, vrátane školeného personálu a jeho poistenia. To znamená, že sa môžete sústrediť na hlavné aktivity vašej firmy bez potreby manažovať ďalšie zamestnanecké povinnosti.
    </p>

    <h2>3. Zabezpečenie odbornosti a dodržanie legislatívy</h2>
    <p>
      Odborné čistenie odlučovačov ropných látok si vyžaduje špecializované znalosti a skúsenosti. Profesionálne firmy majú tím odborníkov, ktorí rozumejú všetkým technickým detailom a postupom potrebným na správne čistenie.
    </p>
    <p>
      Okrem toho, legislatíva týkajúca sa ochrany životného prostredia a správneho nakladania s nebezpečnými odpadmi, ako sú ropné produkty, je veľmi prísna. Zákon o vodách (č. 364/2004 Z.z.) a Zákon o odpadoch (č. 79/2015 Z.z.) stanovujú jasné normy a požiadavky, ktoré musia prevádzkovatelia dodržiavať. Externé firmy na čistenie ORL majú odborné znalosti o týchto právnych požiadavkách a zabezpečia, že vaša prevádzka bude v súlade s predpismi, čím sa vyhnete možným sankciám.
    </p>

    <h2>4. Efektivita a minimalizácia rizika porúch</h2>
    <p>
      Zanedbané odlučovače ropných látok môžu viesť k vážnym prevádzkovým problémom, ako je pretečenie, znížená účinnosť alebo dokonca ekologické havárie. Pravidelné a profesionálne čistenie minimalizuje riziko porúch a predlžuje životnosť vášho zariadenia. Outsourcing znamená, že firma, ktorá čistenie vykonáva, má systematický prístup k údržbe, ktorý je založený na dlhoročných skúsenostiach a osvedčených postupoch. To zaručuje, že odlučovače budú fungovať efektívne a spoľahlivo.
    </p>

    <h2>5. Flexibilita a dostupnosť špecializovaných služieb</h2>
    <p>
      Externé firmy na čistenie ORL ponúkajú flexibilné služby, ktoré môžete prispôsobiť vašim potrebám. Bez ohľadu na to, či potrebujete pravidelnú údržbu alebo akútne čistenie, outsourcing vám poskytuje potrebnú flexibilitu. 
    </p>

    <h2>Záver</h2>
    <p>
      Outsourcing čistenia odlučovačov ropných látok prináša množstvo výhod, od úspory nákladov na vybavenie a licencie až po zníženie personálnych nákladov a zabezpečenie dodržania legislatívy. Profesionálne firmy ponúkajú nielen špecializované služby, ale aj skúsenosti a flexibilitu, ktoré môžu výrazne zvýšiť efektivitu a spoľahlivosť vašej prevádzky. Ak hľadáte spoľahlivého partnera na čistenie ORL, neváhajte nás kontaktovať.
    </p>
    `,
  },
  {
    id: 3,
    title: "Ako naše služby čistenia odlučovačov ropných látok prispievajú k udržateľnosti",
    excerpt: "Objavte, ako profesionálne čistenie ORL podporuje ekologickú udržateľnosť a pomáha chrániť životné prostredie",
    date: "2023-06-30",
    category: "Udržateľnosť",
    image: "/pics_blog/sustain.jpg",
    content: `
    <h2>Úvod</h2>
    <p>
      V dnešnej dobe je udržateľnosť jedným z kľúčových faktorov úspechu pre podniky naprieč rôznymi odvetviami. Čoraz viac spoločností hľadá spôsoby, ako minimalizovať svoj vplyv na životné prostredie (či už dobrovoľne alebo aj nie) a dosiahnuť trvalo udržateľný rozvoj. Naša firma, ktorá sa špecializuje na čistenie odlučovačov ropných látok, sa zaväzuje nielen k ochrane životného prostredia, ale aj k podpore udržateľnosti v prevádzkových procesoch našich klientov. V tomto článku vám ukážeme, ako naše služby prispievajú k ekologickej a trvalo udržateľnej budúcnosti.
    </p>

    <h2>1. Ochrana vodných zdrojov a životného prostredia</h2>
    <p>
      Odlučovače ropných látok sú navrhnuté tak, aby zabránili úniku nebezpečných látok, ako sú oleje a ropné produkty, do kanalizačných systémov a vodných zdrojov. Ak nie sú pravidelne udržiavané a čistené, môžu stratiť svoju účinnosť, čo vedie k znečisteniu vody a pôdy. Naše profesionálne služby čistenia odlučovača ropných látok pomáhajú zaistiť, že odlučovače fungujú optimálne a účinne odstraňujú znečisťujúce látky.
    </p>
    <p>
      Čistením ORL minimalizujeme riziko kontaminácie podzemných vôd, riek a potokov. Voda je jedným z najcennejších zdrojov na našej planéte, a preto jej ochrana hrá zásadnú úlohu v našich službách.
    </p>

    <h2>2. Znižovanie odpadu a správna likvidácia nebezpečných látok</h2>
    <p>
      Jedným z hlavných cieľov našich služieb je správne nakladanie s odpadom. Ropné produkty a kal, ktoré odlučovače zachytávajú, sú považované za nebezpečný odpad, ktorý vyžaduje špeciálne zaobchádzanie. Naša firma zabezpečuje, že všetky zachytené nečistoty sú bezpečne odstránené a ekologicky likvidované v súlade s platnými environmentálnymi predpismi.
    </p>
    <p>
      Tento zodpovedný prístup k likvidácii odpadu zaručuje, že minimalizujeme ekologickú stopu našich klientov a zároveň predchádzame negatívnym dopadom na životné prostredie.
    </p>

    <h2>3. Predĺženie životnosti zariadení a zníženie nákladov</h2>
    <p>
      Pravidelná údržba a čistenie odlučovačov ropných látok nielenže zvyšuje ich účinnosť, ale tiež predlžuje ich životnosť. Zanesené odlučovače môžu viesť k zbytočným poruchám, čo si často vyžaduje nákladné opravy alebo dokonca výmenu celého zariadenia.
    </p>
    <p>
      Vďaka našim profesionálnym službám predchádzame týmto problémom a pomáhame firmám efektívne riadiť svoje zdroje. Dlhšia životnosť zariadení znamená menej odpadu a menšiu potrebu výroby nových zariadení, čo prispieva k udržateľnosti.
    </p>

    <h2>4. Energetická efektívnosť</h2>
    <p>
      Pravidelne čistené odlučovače fungujú efektívnejšie, čo vedie k nižšej spotrebe energie. Zanedbané a znečistené zariadenia potrebujú viac energie na dosiahnutie rovnakého výsledku ako správne udržiavané odlučovače. Naša firma pomáha optimalizovať energetickú náročnosť týchto systémov, čo znižuje uhlíkovú stopu vašej prevádzky.
    </p>

    <h2>5. Podpora legislatívnych a environmentálnych noriem</h2>
    <p>
      Dodržiavanie zákonov a predpisov týkajúcich sa ochrany životného prostredia je dnes pre firmy nevyhnutné. Naše služby sú navrhnuté tak, aby zabezpečili, že vaša prevádzka bude vždy v súlade s platnou legislatívou. To zahŕňa správne nakladanie s nebezpečným odpadom, správnu údržbu odlučovačov a prevenciu únikov ropných látok do životného prostredia.
    </p>

    <h2>Záver</h2>
    <p>
      Naše služby čistenia odlučovačov ropných látok hrajú kľúčovú úlohu v podpore udržateľnosti. Ochrana vodných zdrojov, znižovanie odpadu, predĺženie životnosti zariadení a znižovanie energetickej spotreby sú len niektoré z výhod, ktoré outsourcing čistenia ORL prináša vašej prevádzke. Ak hľadáte spoľahlivého partnera, ktorý vám pomôže dosiahnuť environmentálne ciele, neváhajte nás kontaktovať.
    </p>
    `,
  }
];

function Blog() {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(blogPosts.length / postsPerPage);

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  return (
    <div className="container mx-auto px-4 py-12 bg-gray-100">
      <h1 className="text-5xl font-bold mb-12 text-center text-gray-800">Blog</h1>
      

      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {currentPosts.map((post) => (
          <div key={post.id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
            <div className="p-4">
              <h2 className="text-xl font-semibold mb-2">
                <Link to={`/blog/${post.id}`} className="hover:text-blue-600 transition-colors duration-200">
                  {post.title}
                </Link>
              </h2>
              <p className="text-gray-600 mb-4">{post.excerpt}</p>
              <div className="flex items-center justify-between">
                <span className="text-sm bg-gray-200 px-2 py-1 rounded">{post.category}</span>
                <span className="text-sm text-gray-500">{post.date}</span>
              </div>
            </div>
            <div className="p-4 border-t">
              <Link to={`/blog/${post.id}`} className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
                Čítaj ty ďáble!
              </Link>
            </div>
          </div>
        ))}
      </div>
      
      <div className="mt-12 flex justify-center items-center">
        <button 
          onClick={handlePreviousPage} 
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-200 text-gray-800 rounded mr-4 hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Previous
        </button>
        <span className="text-xl font-semibold mx-4">
          Page {currentPage} of {totalPages}
        </span>
        <button 
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gray-200 text-gray-800 rounded ml-4 hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Blog;
