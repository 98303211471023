'use client'

import React from 'react'
import { motion } from 'framer-motion'
import { ChevronDown, ChevronUp, Milestone, Award, Users, Rocket } from 'lucide-react'
import { FaLeaf, FaCalculator, FaCalendarAlt, FaRecycle, FaShieldAlt } from 'react-icons/fa';

const timelineEvents = [
  { year: 2010, title: "Založenie spoločnosti", description: "Naša cesta začala v malej garáži.", icon: Rocket },
  { year: 2015, title: "Prvý veľký klient", description: "Získali sme prvého veľkého klienta.", icon: Award },
  { year: 2018, title: "Medzinárodná expanzia", description: "Otvorili sme pobočky Rakúsku a Českej republike.", icon: Milestone },
  { year: 2023, title: "500 zamestnancov", description: "Dosiahli sme míľnik 500 zamestnancov.", icon: Users },
]

export default function AboutUs() {
  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <section className="relative py-16 px-4 sm:px-6 lg:px-8 bg-gradient-to-r from-blue-600 to-orange-500">
        <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-4">
              O nás
            </h1>
            <div className="flex justify-center mb-6">
              <FaLeaf className="text-white text-4xl" />
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="prose lg:prose-xl">
            <p className="text-lg text-gray-700 leading-relaxed">
              Naša firma vznikla v roku 2022 s cieľom priniesť inovatívne a dostupné riešenia v oblasti čistenia odlučovačov ropných látok. Sme mladý, dynamický tím, ktorí sa špecializuje na poskytovanie kvalitných, efektívnych a ekologických služieb pre podniky a inštitúcie, ktoré sa musia postarať o správnu údržbu svojich odlučovačov ropných látok.
            </p>
            <p className="text-lg text-gray-700 leading-relaxed mt-6">
              Naším hlavným cieľom je zabezpečiť, aby váš odlučovač ropných látok bol vždy v optimálnom stave a plnili svoju funkciu v súlade s legislatívou.
            </p>
          </div>
        </div>
      </section>

      {/* Advantages Section */}
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">
            <span className="px-6 py-3 bg-blue-600 text-white inline-block">Naše výhody</span>
          </h2>
          <p className="text-center text-lg text-gray-600 mb-12 max-w-3xl mx-auto">
            Naša firma si uvedomuje dynamiku dnešnej doby a preto zjednodušuje dostupnosť služby pre zákazníka prostredníctvom niekoľkých inovácií týkajúcich sa výpočtu a rezervácie odstraňovania kalov z ORL
          </p>
          
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
              <div className="flex items-center mb-4">
                <FaCalendarAlt className="text-blue-600 text-3xl mr-4" />
                <h3 className="text-xl font-semibold text-gray-900">Rezervačný systém online</h3>
              </div>
              <p className="text-gray-600">
                Ponúkame pohodlný a jednoduchý rezervačný systém, ktorý vám umožní naplánovať si čistenie odlučovača ropných látok presne podľa vašich potrieb. Už žiadne telefonovanie a čakanie – všetko vybavíte online, kedykoľvek a kdekoľvek.
              </p>
            </div>

            <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
              <div className="flex items-center mb-4">
                <FaCalculator className="text-orange-500 text-3xl mr-4" />
                <h3 className="text-xl font-semibold text-gray-900">Kalkulačka ceny čistenia</h3>
              </div>
              <p className="text-gray-600">
                Naša jedinečná kalkulačka vám na základe zadaných parametrov – ako sú veľkosť odlučovača, frekvencia čistenia, typ prevádzky a ďalšie faktory – vypočíta presnú cenu služby. To vám umožní rýchlo získať prehľad o nákladoch a lepšie naplánovať rozpočet na údržbu.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="bg-gradient-to-r from-blue-50 to-orange-50 rounded-2xl p-8 lg:p-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Naša misia</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="flex flex-col items-center text-center p-6">
                <FaRecycle className="text-blue-600 text-4xl mb-4" />
                <p className="text-gray-700">
                  Sme presvedčení, že správna údržba odlučovačov ropných látok je kľúčová pre ochranu životného prostredia a dodržiavanie legislatívnych noriem. Pravidelným čistením pomáhate znižovať ekologickú stopu vašej nehnuteľnosti.
                </p>
              </div>
              <div className="flex flex-col items-center text-center p-6">
                <FaShieldAlt className="text-orange-500 text-4xl mb-4" />
                <p className="text-gray-700">
                  Veríme v transparentnosť a efektívnosť, preto sme sa rozhodli priniesť vám moderné riešenia, ktoré šetria čas, znižujú náklady a zjednodušujú celý proces údržby odlučovačov ropných látok.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}