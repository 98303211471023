import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { blogPosts } from './Blog';
import parse from 'html-react-parser';

function BlogPost() {
  const { id } = useParams();
  const post = blogPosts.find(post => post.id === parseInt(id));
  
  if (!post) {
    return <div>Post not found</div>;
  }

  const renderContent = () => {
    return parse(post.content);
  };

  return (
    <div className="container mx-auto px-4 py-12 bg-gray-100">
      <Link to="/blog" className="text-blue-600 hover:text-blue-800 mb-4 inline-block">&larr; Back to Blog</Link>
      <article className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-8">
          <h1 className="text-4xl font-bold mb-4 text-gray-800">{post.title}</h1>
          <div className="flex items-center justify-between mb-6">
            <span className="text-sm bg-blue-100 text-blue-800 px-3 py-1 rounded-full font-semibold">
              {post.category}
            </span>
            <span className="text-sm text-gray-500">{post.date}</span>
          </div>
          
          <div className={`
            prose prose-lg max-w-none text-gray-700 space-y-6
            [&_h2]:text-2xl [&_h2]:font-black [&_h2]:mt-12 [&_h2]:mb-6 
            [&_h2]:tracking-tight [&_h2]:text-gray-900
          `}>
            {renderContent()}
          </div>
        </div>
      </article>
    </div>
  );
}

export default BlogPost;